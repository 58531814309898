<template>
    <a-tabs v-model:activeKey="activeKey">
        <template v-for="(item, index) in tabList" :key="item.key">
            <a-tab-pane :tab="item.key">
                <table-page :config="item.config" />
            </a-tab-pane>
        </template>
    </a-tabs>
</template>

<script>
import TablePage from "@/components/TablePage";
import { getProfile } from "@/utils/session";
import { customerSource, highSeasReasons } from '@/utils/public';
export default {
    name: 'SaasAdminfollowRecordDetail',
    components: { TablePage },
    props: ['selectedRows'],
    data() {
        return {
            tabList: [{
                key: "留服客户公海",
                permissions: '/agency/institutionsCustomerHighSeas/list',
                config: {
                    dataUrl: '/agency/institutionsCustomerHighSeas/list',
                    filter: {
                        initUrl: '/agency/allocationCustomer/list/get',
                        controls: [
                            {
                                key: 'nameContains',
                                label: '客户姓名',
                                type: 'text'
                            },
                            {
                                key: 'phoneNumberContains',
                                label: '手机号',
                                type: 'text',
                                config: {
                                    rules: 'phoneNumber'
                                }
                            },
                            {
                                key: 'wechatContains',
                                label: '微信号',
                                type: 'text',
                            },
                            {
                                key: 'staffPersonIdIs',
                                label: '客服',
                                type: 'select',
                                width: '260',
                                config: {
                                    options: "employeesList"
                                }
                            },
                        ]
                    },
                    /* 表格排序 */
                    sorter: {
                        /* 可排序列 */
                        sortableColumns: ['putInTime'],
                        /* 默认排序 */
                        defaultSort: '-putInTime'
                    },
                    checkbox: true,
                    actions: [
                        {
                            key: 'edit',
                            text: '领取客资',
                            icon: 'edit',
                            type: 'form',
                            isSelected: true,
                            permission: '/agency/institutionsCustomerHighSeas/receiveCustomerResources',
                            config: {
                                color: 'primary',
                                title: '领取客资',
                                info: (rows) => {
                                    return `您共选择 ${rows.length} 条客资，是否领取？`
                                },
                                // initUrl: '/institutions/customer/setAttributablePerson/get',
                                submitUrl: '/agency/institutionsCustomerHighSeas/receiveCustomerResources',
                                submitText: '确定',
                                controls: []
                            }
                        },
                        {
                            key: 'edit',
                            text: '分配客资',
                            icon: 'edit',
                            type: 'form',
                            isSelected: true,
                            permission: '/agency/institutionsCustomerHighSeas/allocationOfCustomerCapital',
                            config: {
                                color: 'primary',
                                title: '分配客资',
                                info: (rows) => {
                                    return `您共选择 ${rows.length} 条客资，请选择归属人`
                                },
                                initUrl: '/agency/institutionsCustomerHighSeas/allocationOfCustomerCapital/get',
                                submitUrl: '/agency/institutionsCustomerHighSeas/allocationOfCustomerCapital',
                                submitText: '确定',
                                controls: [
                                    {
                                        key: 'attributablePersonId',
                                        label: '归属人',
                                        type: 'select',
                                        required: true,
                                        config: {
                                            options: 'attributablePersonList'
                                        }
                                    },
                                    {}
                                ]
                            }
                        }
                    ],
                    columns: [
                        {
                            key: 'name',
                            title: '客户姓名',
                            width: 150,
                        },

                        {
                            key: 'phoneNumber',
                            title: '手机号',
                            width: 150,
                        },
                        {
                            key: 'wechat',
                            title: '微信号',
                            width: 150,
                        },
                        {
                            key: 'attributablePerson.name',
                            title: '客资归属人',
                            width: 150,
                        },
                        {
                            key: 'staffPerson.name',
                            title: '客服',
                            width: 100,
                        },
                        {
                            key: 'putInTime',
                            title: '移入公海时间',
                            type: 'datetime',
                            width: 150,
                        },
                        {
                            key: 'moveInReason',
                            title: '移入原因',
                            width: 150,
                        },
                        {
                            key: 'customerSource',
                            title: '客户来源',
                            width: 150,
                        },
                        {
                            key: 'name',
                            title: '操作',
                            type: 'actions',
                            fixed: 'right',
                            width: '80px'
                        }
                    ],
                    rowActions: [
                        {
                            key: 'detail',
                            text: '详情',
                            type: 'blank',
                            permission: '/agency/institutionsCustomerHighSeas/details',
                            config: {
                                title: '客户详情',
                                component: () => import("./views/highSeasTab3Detail")
                            }
                        },
                    ]
                }
            }, {
                key: "机构客户公海",
                permissions: '/agency/highSeas/list',
                config: {
                    dataUrl: '/agency/highSeas/list',
                    filter: {
                        initUrl: '/agency/highSeas/list/get',
                        controls: [
                            {
                                key: 'nameContains',
                                label: '客户姓名',
                                type: 'text'
                            },
                            {
                                key: 'institutionsIdIs',
                                label: '所属机构',
                                type: 'select',
                                config: {
                                    options: 'institutionsList'
                                }
                            },
                            {
                                key: 'moveInReasonIs',
                                label: '移入原因',
                                type: 'select',
                                config: {
                                    options: highSeasReasons
                                }
                            },
                            {
                                key: 'sourceChannelIs',
                                label: '来源渠道',
                                type: 'select',
                                config: {
                                    options: customerSource
                                }
                            },
                            {}
                        ]
                    },
                    /* 表格排序 */
                    sorter: {
                        /* 可排序列 */
                        sortableColumns: ['putInTime'],
                        /* 默认排序 */
                        defaultSort: '-putInTime'
                    },
                    actions: [],
                    columns: [
                        {
                            key: 'name',
                            title: '客户姓名',
                            width: '150px',
                        },
                        // {
                        //   key: 'userLabelList',
                        //   title: '客户标签',
                        //   width: 280,
                        //   type: 'color',
                        //   config: {
                        //     textkey: 'content',
                        //     colorkey: 'color'
                        //   }
                        // },
                        {
                            key: 'institutions.name',
                            title: '所属机构',
                            width: '150px',
                        },
                        {
                            key: 'phoneNumber',
                            title: '手机号',
                            width: '150px',
                        },
                        {
                            key: 'putInTime',
                            title: '移入公海时间',
                            type: 'datetime',
                            width: '150px',
                        },
                        {
                            key: 'moveInReason',
                            title: '移入原因',
                            width: '150px',
                        },
                        {
                            key: 'sourceChannel',
                            title: '来源渠道',
                            width: '150px',
                        },
                        {
                            key: 'attribution.name',
                            title: '最后归属人',
                            width: '150px',
                        },

                        {
                            key: 'followUpWithPeople.name',
                            title: '最后跟进人',
                            width: '150px',
                        },
                        {
                            key: 'institutionsFollowUp.followUpTime',
                            title: '最后跟进时间',
                            width: '150px',
                            type: 'datetime'
                        },
                        {
                            key: 'institutionsFollowUp.followUpContent',
                            title: '最后跟进情况',
                            width: '150px',
                            ellipsis: true
                        },
                        {
                            key: 'name',
                            title: '操作',
                            type: 'actions',
                            fixed: 'right',
                            width: document.documentElement.clientWidth <= 768 ? '50px' : '80px'
                        }
                    ],
                    rowActions: [
                        {
                            key: 'detail',
                            text: '详情',
                            type: 'blank',
                            permission: '/agency/highSeas/details',
                            config: {
                                title: '客户详情',
                                component: () => import("./views/highSeasDetail")
                            }
                        },
                    ]
                }
            }, {
                key: "未领取客资",
                permissions: '/retention/retentionClients/list',
                config: {
                    dataUrl: '/retention/retentionClients/list',
                    filter: {
                        //initUrl: '/agency/institutionsCustomer/list/get',
                        controls: []
                    },
                    /* 表格排序 */
                    sorter: {
                        /* 可排序列 */
                        sortableColumns: ['createdDate'],
                        /* 默认排序 */
                        defaultSort: '-createdDate'
                    },
                    checkbox: true,
                    listFilter: {
                        key: 'quickCheck',
                        defaultValue: 0,
                        options: [
                            {
                                value: 0,
                                name: '待确认客资'
                            },
                            {
                                value: 1,
                                name: '重复客资',
                                tips: '无法录入的重复客资，做备份，可删除'
                            },
                        ]
                    },
                    columns: [
                        {
                            key: 'createdDate',
                            title: '留资时间',
                            type: 'datetime',
                            width: '100px',
                        },
                        {
                            key: 'customerName',
                            title: '客户姓名',
                            width: '100px',
                        },
                        {
                            key: 'mobilePhoneNumber',
                            title: '手机号',
                            width: '150px',
                        },
                        {
                            key: 'consultingProject',
                            title: '咨询项目',
                            width: '100px',
                        },
                        {
                            key: 'intendedCountries',
                            title: '意向国家',
                            width: '100px',
                        },
                        {
                            key: 'sourceChannel',
                            title: '客户来源',
                            width: '150px'
                        },
                        {
                            key: 'otherInformation',
                            title: '其他信息',
                            width: '100px',
                            ellipsis: true
                        }
                    ],
                    actions: [
                        {
                            key: 'edit',
                            text: '领取客户',
                            icon: 'plus',
                            type: 'dialog',
                            isSelected: true,  // 是否必勾选
                            determine: true,  // 是否需要判定条
                            permission: '/retention/retentionClients/receiveCustomer',
                            modalObj: (rowData, tableData) => {
                                return `您共选择 ${rowData.length} 条客资，是否领取？`
                            },
                            config: {
                                title: '领取客资',
                                submitUrl: '/retention/retentionClients/receiveCustomer',
                                submitText: '确认',
                                controls: [

                                ]
                            }
                        },
                        {
                            key: 'edit',
                            text: '分配客户',
                            icon: 'plus',
                            type: 'dialog',
                            isSelected: true,  // 是否必勾选
                            determine: true,  // 是否需要判定条
                            permission: '/retention/retentionClients/assignCustomers',
                            modalObj: (rowData, tableData) => {
                                return `您共选择 ${rowData.length} 条客资，请选择客资归属人`
                            },
                            config: {
                                title: '分配客资',
                                initUrl: '/retention/retentionClients/assignCustomers/get',
                                submitUrl: '/retention/retentionClients/assignCustomers',
                                submitText: '确认',
                                controls: [
                                    {
                                        key: 'attributablePersonId',
                                        label: '客资归属人',
                                        type: 'souSelect',
                                        required: true,
                                        config: {
                                            options: "employeesList"
                                        }
                                    },
                                ]
                            }
                        },
                        {
                            key: 'edit',
                            text: '删除客资',
                            icon: 'plus',
                            type: 'dialog',
                            isSelected: true,  // 是否必勾选
                            determine: true,  // 是否需要判定条
                            permission: '/retention/retentionClients/delete',
                            modalObj: (rowData, tableData) => {
                                return `您共选择 ${rowData.length} 条客资，是否删除？`
                            },
                            config: {
                                title: '删除客资',
                                submitUrl: '/retention/retentionClients/delete',
                                submitText: '确认',
                                controls: [

                                ]
                            }
                        },
                    ],
                    rowActions: [

                    ]
                }
            }],
            permissions: null,
            activeKey: '机构客户公海',
        };
    },
    mounted() {
        this.getProfile()
    },
    methods: {
        async getProfile() {
            try {
                let profile = await getProfile();
                this.permissions = profile.everyPermissions;
                let url = ['/agency/institutionsCustomerHighSeas/list', '/agency/highSeas/list', '/retention/retentionClients/list']
                let haveUrl = []
                url.map((item) => {
                    if (this.permissions(item)) {
                        haveUrl.push(item)
                    }
                })
                const filteredB = this.tabList.filter(item => haveUrl.includes(item.permissions));
                this.tabList = filteredB
                this.activeKey = this.tabList[0].key
            } catch ({ message, status }) {
                if (status !== 401) {
                    this.$message.error(message);
                }
            }
        },
    }

};
</script>

<style lang="less" scoped></style>